<template>
  <div class="newprolist">
    <div class="top">
    	<div class="lt">
    		<h6 :title='data.list[0].title'>{{data.list[0].title}}</h6>
    		<div class="tip">
    			<p>{{data.list[0].price | priceF}}元{{data.list[0].ProductVolume?data.list[0].ProductVolume:''}}</p>
    			<p>超值</p>
    		</div>
    		<div class="promsg">
    			<div class="lts">
	    			<p>新品优惠价</span>
	    			<p><span>&yen;</span>{{data.list[0].price | priceF}}</p>
	    		</div>
	    		<div class="rts">
	    			<p>原价：&yen;<span>{{data.list[0].oldprice}}</span></p>
	    			<button @click='link(data.list[0],NewProductDto.Id)'>立即购买</button>
	    		</div>
    		</div>
    	</div>
    	<div class="rt">
			<div class="imgbox">
				<img :src="data.list[0].url" >
			</div>
    	</div>
    </div>
    <div class="listbox">
    	<el-row :gutter='20'>
		  <el-col :span="6" v-for='item,index in data.list' :key='index' v-if='index>=1'>
		  	<div class="box">
		  		<div class="imgbox">
			  		<img :src="item.url">
			  	</div>
			  	<div class="promsg">
			  		<h6 :title='item.title'>{{item.title}}</h6>
			  		<div class="old">
			  			<p>新品优惠</p>
			  			<p>原价：&yen;<span>{{item.oldprice}}</span></p>
			  		</div>
			  		<div class="new">
			  			<p><span>&yen;</span>{{item.price | priceF}}</p>
			  			<button @click='link(item,NewProductDto.Id)'>立即购买</button>
			  		</div>
			  	</div>
		  	</div>
		  </el-col>
		</el-row>
    </div>
  </div>
</template>
<script>
export default {
  name: 'newprolist',
  props: {
    data: Object,
    NewProductDto:null
  },
  methods:{
  	link(i,Id){
  		// 跳转详情
  		this._router({then:()=>{
  			var id = i.Id + '.html'
	        this.$router.push({path:'/predetail',query:{idcx:Id,id:id,}})
	    }})
  	}
  },
  filters:{
  	priceF(v){
  		return parseInt(v);
  	}
  }
}
</script>
<style  lang="scss" scoped>
.newprolist {
  width: 1200px;margin:0 auto;background: #9a9bfe;border-radius: 60px;padding-top:12px;padding-bottom:30px;
  .top {
  	width: 1176px;margin:0 auto;height: 400px;position: relative;
  	.lt {
		width: 586px;height: 400px;border-radius: 60px;background: #fff;position: absolute;top:0px;left:0px;z-index: 9;overflow: hidden;box-shadow: 2px 0px 2px 2px rgba(0,0,0,0.2);
		&:after {
			content: '';display: block;width: 36px;height: 36px;background: #9a9bfe;border-radius:80px;position: absolute;top:182px;left:-20px;
		}
		h6 {
			width: 500px;margin:0 auto;font-size: 24px;font-weight: normal;text-align: center;margin-top:60px;white-space: nowrap; text-overflow: ellipsis;overflow: hidden;word-break: break-all;
		}
		.tip {
			width: 332px;height: 57px;margin:0 auto;margin-top:28px;
			p {
				float:left;
			}
			p:nth-child(1) {
				width: 218px;height: 57px;background: url('../../assets/img/newprolist/tipbg.jpg') 0px 0px repeat;color:#fff;border-radius: 5px;position: relative;font-style: italic;font-size: 26px;letter-spacing: 3px;font-weight:bold;line-height: 57px;text-align: center;
				&:after {
					content: '';display: block;position: absolute;width: 25px;height: 25px;border-radius: 50%;background: #fff;top:16px;left:-13px;
				}
			}
			p:nth-child(2) {
				width: 109px;height: 53px;border:2px dashed #1826f8;color:#1826f8;border-top-left-radius: 20px;border-top-right-radius: 20px;border-bottom-right-radius: 20px;margin-left:1px;text-align:center;line-height: 53px;font-size: 26px;font-weight: bold;
			}
		}
		.promsg {
			width: 330px;height: 86px;margin:0 auto;margin-top:30px;
			.lts {
				width: 160px;float:left;
				p:nth-child(1) {
					width: 82px;height: 22px;background: #1826f8;line-height: 22px;text-align: center;font-size: 12px;color:#fff;
				}
				p:nth-child(2) {
					color:#d3122e;font-size: 56px;font-weight: bold;margin-top:5px;
					span {
						font-size: 26px;
					}
				}
			}
			.rts {
				float:right;
				p {
					padding-left:18px;font-size: 14px;color:#989697;margin-top:20px;
					span {
						text-decoration:line-through;
					}
				}
				button {
					width: 142px;height: 36px;background: #171951;color:#fff;font-size: 14px;border-radius: 20px;line-height: 36px;margin-top:8px;
					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
  	}
  	.rt {
  		width: 720px;height: 400px;border-radius: 60px;position: absolute;top:0px;right:0px;z-index: 8;background: #fff;
  		.imgbox {
  			width: 590px;height: 100%;display: flex;justify-content: center;align-items: center;float:right;
  			img {
	  			display: block;width: 350px;height: 350px;
	  		}
  		}
  	}
  }
  .listbox {
  	width: 1176px;margin:0 auto;
  	.el-row {
	  	.el-col-6 {
	  		width: 20%;margin-top:30px;
	  		.box {
	  			background: #fff;position: relative;height: 300px;border-radius: 30px;overflow: hidden;
	  			div {
	  				position: relative;z-index: 9;
	  			}
	  			.imgbox {
	  				height: 187px;width: 100%;display: flex;align-items: center;justify-content: center;
	  				img {
	  					display: block;max-width: 150px;max-height: 150px;
	  				}
	  			}
	  			.promsg {
	  				width: 100%;height: 114px;border-top-left-radius: 30px;border-top-right-radius: 30px;background: #fff;box-shadow: 0px -2px 8px -2px rgba(0,0,0,0.2);
	  				h6 {
	  					font-size: 16px;font-weight: normal;height: 40px;line-height: 40px;text-align: center;padding:0 10px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;
	  				}
	  				.old {
	  					zoom:1;
	  					&:after {
	  						content: '';display: block;clear:both;
	  					}
	  					p:nth-child(1) {
	  						width: 70px;height: 20px;line-height: 20px;text-align: center;background: #1826f8;color:#fff;font-size: 14px;float:left;margin-left:16px;
	  					}
	  					p:nth-child(2) {
	  						font-size: 14px;color:#989697;float:right;margin-right:20px;
	  						span {
								text-decoration:line-through;
	  						}
	  					}
	  				}
	  				.new {
	  					p {
	  						font-size: 22px;font-weight: bold;color:#d3122e;margin-left:16px;float:left;margin-top:2px;margin-top:12px;
	  						span {
	  							font-size: 18px;margin-right: 2px;
	  						}
	  					}
	  					button {
	  						width: 100px;height: 32px;background: #171951;color:#fff;border-radius: 20px;line-height: 32px;text-align: center;float:right;margin-right:16px;margin-top:8px;
	  						&:hover {
	  							opacity: 0.8;
	  						}
	  					}
	  				}
	  			}
	  		}
	  	}
	  	.el-col-6:nth-child(5n+1) {
	  		.box  {
	  			background: none;
	  			&:before,&:after {
					content: '';display: block;position: absolute;width: 50%;height: 300px;top:0px;z-index: 8;
		  		}
		  		&:before {
		  			left:0px;background: #ffe69b;
		  		}
		  		&:after {
		  			right:0px;background: #fff;
		  		}
	  		}
	  	}
	  	.el-col-6:nth-child(5n+3) {
	  		.box {
	  			background: none;
	  			&:before,&:after {
					content: '';display: block;position: absolute;width: 50%;height: 300px;top:0px;z-index: 8;
		  		}
		  		&:before {
		  			left:0px;background: #6495cb;
		  		}
		  		&:after {
		  			right:0px;background: #fff;
		  		}
	  		}
	  	}
	  	.el-col-6:nth-child(5n+4) {
	  		.box {
	  			background: none;
	  			&:before,&:after {
					content: '';display: block;position: absolute;width: 50%;height: 300px;top:0px;z-index: 8;
		  		}
		  		&:before {
		  			left:0px;background: #ffe69b;
		  		}
		  		&:after {
		  			right:0px;background: #fff;
		  		}
	  		}
	  	}
	  }
  }
}
</style>
