<template>
  <div class="newprotop">
    <img :src="url">
  </div>
</template>
<script>
export default {
  name: 'newprotop',
  props: {
    url: null
  },
  methods:{
  }
}
</script>
<style  lang="scss" scoped>
.newprotop {
	// background: url('../../assets/img/newprotop/bg.jpg') center 0px no-repeat;
  width: 100%;
  position: relative;overflow: hidden;
  // width: calc(100vw - 9px);
  img {
  	margin-left:calc((100vw - 1920px - 9px)/2);
  }
}
@media screen and (max-width: 1200px) { //布局容器宽度自适应<1200宽度的时候固定为1200
    .newprotop {
      width:1200px;
      img {
      // position: absolute;top:0px;left:0px;width: 100%;height: auto;
      margin-left:calc((1200px - 1920px)/2);
    }
    }
}
</style>
