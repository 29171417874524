<!-- 新品上市 -->
<template>
  <div class="newpro">
    <newprotop :url="topbg"></newprotop>
    <newprolist v-if='newprolistinit' :data='arrdata' :NewProductDto='NewProductDto' class='newprolist'></newprolist>
  	<div class="layout">
  		<likes class='likes'></likes>
  		<infiniteImg type='0' class='infiniteImg'></infiniteImg>
  	</div>
  </div>
</template>
<script>
import likes from '@/components/likes/likes.vue';//猜你喜欢
import infiniteImg from '@/components/infiniteImg/infiniteImg.vue';//底部滚动展示
import newprotop from '@/components/newprotop/newprotop.vue';//新品头部
import newprolist from '@/components/newprolist/newprolist.vue';//新品list
export default {
  name: 'newpro',
  metaInfo() {
    return {
      title:this.htmltitle,
      meta: [
        {
        name: "description",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoDescrition
      },
      {
        name: "Keywords",
        content:JSON.parse(window.sessionStorage.getItem('commonseo')).SeoKey
      }
        ]
    }
  },
  data(){
    return {
      // htmltitle:'新品上市-餐媒网',
      htmltitle:'loading...',
      arrdata:{
        // spe:{
        //   title:'微微定新品荷包广告纸巾',
        //   oldprice:2000,
        //   price:449,
        //   tip:'500元1万盒',
        //   url:require('../assets/img/newprolist/topbg.jpg')
        // },
        list:[
          // {title:'微微定新品荷包广告纸巾213213123',oldprice:2000,price:449,url:require('../assets/img/newprolist/img.jpg'),tip:'500元1万盒',},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img1.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img2.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img3.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img4.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img1.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img2.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img3.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img4.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img1.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img2.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img3.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img4.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img1.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img2.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img3.jpg')},
          // {title:'微微定新品荷包广告纸巾',oldprice:2000,price:449,url:require('../assets/img/newprolist/img4.jpg')},
        ]
      },
      topbg:null,//顶部背景
      newprolistinit:false,//初始化组件
      NewProductDto:null,//新品上市id
    }
  },
  components: {
  	likes,
  	infiniteImg,
    newprotop,
    newprolist
  },
  props: {
    msg: String
  },
  methods:{
    localReset(){
      var CustomerID='';
      if(this.$store.state.user){
        if(this.$store.state.user.CustomerID){
          CustomerID = this.$store.state.user.CustomerID
        }
      }
      this._axios({then:()=>{
        this.axios.post(process.env.VUE_APP_URL+'NewProduct/GetNewProductData?CustomerID='+CustomerID,this.keys())
        .then(res => {
            if(res.data.Success){
              this.$set(this,'topbg',this._src+res.data.Data.NewProductDto.BackGroundIMG)
              var _data = [];
              for(let i in res.data.Data.DzproducttypeVM){
                var _obj = {};
                _obj.title=res.data.Data.DzproducttypeVM[i].AttachTypeName
                _obj.oldprice=res.data.Data.DzproducttypeVM[i].OriginalPrice
                _obj.price=res.data.Data.DzproducttypeVM[i].Price
                _obj.Id=res.data.Data.DzproducttypeVM[i].Id
                _obj.ProductVolume=res.data.Data.DzproducttypeVM[i].ProductVolume
                _obj.tip=123
                _obj.url=this._src +res.data.Data.DzproducttypeVM[i].ImagePath
                _data.push(_obj)
              }
              // console.log(_data)
              this.$set(this,'NewProductDto',res.data.Data.NewProductDto)
              this.$set(this.arrdata,'list',_data)
              this.newprolistinit=true
              if(res.data.Data.NewProductDto.SeoTitle){
                document.title = res.data.Data.NewProductDto.SeoTitle
              }
              if(res.data.Data.NewProductDto.SeoDescrition){
                $('meta[name=description]').attr('content',res.data.Data.NewProductDto.SeoDescrition)
              }
              if(res.data.Data.NewProductDto.SeoKey){
                 $('meta[name=Keywords]').attr('content',res.data.Data.NewProductDto.SeoKey)
              }
            }else{
              this.$MessageBox.alert(res.data.Error,{type:'error'});
            }
          }, res => {
            // this.$MessageBox.alert('系统异常',{type:'error'});
          })
      }})
    }
  },
  mounted(){
    this.$nextTick(()=>{
      // 加载
      var _this = this;
      let _pomise = this.$store.state.headPomise
      this._reduce([_pomise.a,_pomise.b,_pomise.c])
      .then(()=> {
        if(window.sessionStorage.getItem('isReadFirst')){
            window.sessionStorage.removeItem('isReadFirst')
            // this.$store.dispatch("cmtCap",true);//显示隐藏
            $("#app").css('opacity',1)
        }
        window.scrollTo(0,0);//刷新置顶
        console.log('头部加载完成')
        //this._NProgress.done();//关闭加载动画
        this.localReset()
        let _footPomiseA = this.$store.state.footPomiseA
        let _footPomiseB = this.$store.state.footPomiseB
        this._reduce([_footPomiseA,_footPomiseB])
        .then(()=> {
          console.log('内容队列加载')
        })
        .catch(function(e) {
            console.log(e)
        })
      })
      .catch(function(e) {
          console.log(e)
      })
      // end
    })
  }
}
</script>
<style  lang="scss" scoped>
.newpro {
  width: 100%;
  .newprolist {
    margin-top:48px;
  }
	.layout {
		width: 1200px;margin:0 auto;position: relative;
		.likes {
		    width: 1200px;overflow: hidden; margin-top:40px;
		}
		.infiniteImg {
			margin-top:40px;
		}
	}
}
</style>
